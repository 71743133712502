import React from 'react';

const BlvckmenFashion = () => {
  return (
    <div className="fashion-header">
      <h1>Welcome to your path to better dressing</h1>
        <p className="fashion-info">You will love how you look</p>
    </div>
  );
};

export default BlvckmenFashion;
